/* Global */

html {
  height: 100%;
}

body {
  background: url('/assets/images/bg.jpg') var(--bs-secondary) no-repeat center
    center scroll;
  background-size: cover;
  height: 100%;
  color: var(--bs-secondary);
  position: relative;

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--bs-secondary);
    opacity: 0.7;
    z-index: 1;
  }
}

.bg {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}

.logo {
  height: 100px;
  width: auto;
}

footer {
  font-family: 'Poppins', sans-serif;
  font-size: 0.865rem;
}
