@import url('http://fonts.googleapis.com/css?family=Poppins:400,300');
@import url('http://fonts.googleapis.com/css?family=Lato:400,300,700,900');
@import url('http://fonts.googleapis.com/css?family=Great+Vibes');

$primary: #ffcc00;
$secondary: #000000;
$nav-link-color: #fff;

$headings-font-family: 'Lato', sans-serif;
$headings-font-weight: 900;
$font-family-sans-serif: 'Great Vibes', cursive;
